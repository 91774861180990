<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar Ruleta </template>
    <div>
      <form @submit.prevent="save()">
        <div class="mb-3 mx-auto">
          <multiselect v-model="value" label="name" track-by="name" :options="roulettes" @tag="addTag">
          </multiselect>
        </div>
        <b-row>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Pleno</label>
              <vs-input v-model="typeform.pleno" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.pleno.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.pleno.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.pleno.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Semi Pleno</label>
              <vs-input v-model="typeform.semipleno" border class="shadow-lg" :class="{
                'is-invalid': typesubmit && $v.typeform.semipleno.$error,
              }" />
              <div v-if="typesubmit && $v.typeform.semipleno.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.semipleno.required">
                  Este campo es requerido.
                </span>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Calle </label>
              <div>
                <vs-input v-model="typeform.calle" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.calle.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.calle.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.calle.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Cuadro</label>
              <div>
                <vs-input v-model="typeform.cuadro" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.cuadro.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.cuadro.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.cuadro.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Linea</label>
              <div>
                <vs-input v-model="typeform.linea" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.linea.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.linea.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.linea.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Columna</label>
              <div>
                <vs-input v-model="typeform.columna" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.columna.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.columna.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.columna.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Docena</label>
              <div>
                <vs-input v-model="typeform.docena" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.docena.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.docena.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.docena.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Chance simple</label>
              <div>
                <vs-input v-model="typeform.chanceSimple" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.chanceSimple.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.chanceSimple.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.chanceSimple.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Cubre</label>
              <div>
                <vs-input v-model="typeform.cubre" border class="shadow-lg" :class="{
                  'is-invalid': typesubmit && $v.typeform.cubre.$error,
                }" />
                <div v-if="typesubmit && $v.typeform.cubre.$error" class="invalid-feedback">
                  <span v-if="!$v.typeform.cubre.required">
                    Este campo es requerido.
                  </span>
                </div>
              </div>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <div class="mb-3">
              <label>Special Calle</label>
              <div>
                <vs-input v-model="typeform.specialCalle" border class="shadow-lg" />
              </div>
            </div>
          </b-col>
        </b-row>
        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="closeModa()" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import Multiselect from 'vue-multiselect'
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from 'vuex';


export default {
  props: {
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
    roulettes: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { Multiselect },
  data() {
    return {
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      modal: false,
      typeform: {},
      value: null,
    };
  },
  validations: {
    typeform: {
      pleno: {
        required,
      },
      semipleno: {
        required,
      },
      calle: {
        required,
      },
      cuadro: {
        required,
      },
      linea: {
        required,
      },
      columna: {
        required,
      },
      docena: {
        required,
      },
      chanceSimple: {
        required,
      },
      cubre: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess"
    })
  },
  methods: {
    addTag(newTag) {
      const tag = newTag

      this.value.push(tag)
    },
    async save() {
      this.typesubmit = true;
      const rouletteId = this.value._id;

      await this.addGame({ operatorId: this.operator._id, rouletteId, endpoint: "add-roulette" })

      if (!this.success) return Swal.fire("Error adding roulette", "", "info");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Ruleta agregada con exito",
        showConfirmButton: false,
        timer: 1500,
      });
      this.closeModa();
    },
    defaultValue(value) {
      let roundDuration =
        value.target.options[value.target.options.selectedIndex].getAttribute(
          "data-roundDuration"
        );

      this.typeform.roundDuration = roundDuration;
    },
    closeModa() {
      this.typeform = {};
      this.modal = false;
      this.$emit("closeGameMenu");
    },
    ...mapActions({
      addGame: "operators/addGame"
    })
  },
  watch: {
    value(newValue) {

      const {
        pleno,
        semipleno,
        cuadro,
        calle,
        linea,
        columna,
        docena,
        chanceSimple,
        cubre,
        specialCalle
      } = newValue;

      this.typeform = {
        pleno,
        semipleno,
        cuadro,
        calle,
        linea,
        columna,
        docena,
        chanceSimple,
        cubre,
        specialCalle
      }
    }
  }
};
</script>
