<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer centered>
    <template #modal-title> Urls: {{ item.name }} </template>
    <form @submit.prevent="save()">
      <b-row>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("operators.form.endpointAuth") }}:</label>
            <vs-input border class="shadow-lg" placeholder="URL" v-model="urls.endpointAuth"
              :disabled="role !== 'ADMIN' ? true : false">
            </vs-input>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("operators.form.endpointBet") }}:</label>
            <vs-input border class="shadow-lg" placeholder="URL" v-model="urls.endpointBet"
              :disabled="role !== 'ADMIN' ? true : false">
            </vs-input>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("operators.form.endpointRollback") }}:</label>
            <vs-input border class="shadow-lg" placeholder="URL" v-model="urls.endpointRollback"
              :disabled="role !== 'ADMIN' ? true : false"></vs-input>
          </div>
        </b-col>
        <b-col sm="12" md="6">
          <div class="mb-3">
            <label>{{ $t("operators.form.endpointWin") }}:</label>

            <vs-input border class="shadow-lg" placeholder="URL" v-model="urls.endpointWin"
              :disabled="role !== 'ADMIN' ? true : false"></vs-input>
          </div>
        </b-col>
      </b-row>
      <div class="mb-3 mb-0">
        <div class="d-flex">
          <vs-button type="submit" :disabled="role !== 'ADMIN' ? true : false">
            {{ $t("form.save") }}
          </vs-button>
          <vs-button success @click="closeModal" type="button">
            {{ $t("form.abort") }}
          </vs-button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    operatorId: {
      type: String,
      default: () => "",
    },
    urls: {
      type: Object,
      default: () => { },
    },
    role: String,
  },
  data() {
    return {
      modal: false,
      updateEndpoints: {},
    };
  },
  computed: {
    ...mapGetters({
      success: 'operators/getSuccess',
      envs: 'notification/mapEnvs',
      getErrors: 'notification/getErrors'
    })
  },
  methods: {
    openWinNotification(position = null, color, title, text, duration = 10000) {
      this.$vs.notification({
        color,
        position,
        title,
        text,
        duration,
        progress: "auto",
      });
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`
          this.openWinNotification(null, 'warning', 'ERROR', message)
        })
      }
    },
    append(endpoint) {
      if (!endpoint) return "default";
      const endpointFormatted = endpoint.split(/.\//)[2];
      return endpointFormatted;
    },
    endpointValue(endpoint) {
      if (!endpoint) return "default";
      const endpointSplitted = endpoint.split("/");
      const url = `${endpointSplitted[0]}//${endpointSplitted[2]}/`;
      return url;
    },
    formatEndpoints(endpoints) {
      return endpoints;
    },
    compareOldAndNewsEndpoints(oldEnpoints, newEndpoints) {
      for (let endpoint in oldEnpoints) {
        if (oldEnpoints[endpoint] !== newEndpoints[endpoint]) {
          this.updateEndpoints[endpoint] = newEndpoints[endpoint];
        }
      }
    },
    async save() {
      const newEndpoints = this.formatEndpoints(this.urls);
      const { endpointAuth, endpointWin, endpointBet, endpointRollback } =
        this.item;
      const oldEnpoints = {
        endpointAuth,
        endpointWin,
        endpointBet,
        endpointRollback,
      };
      this.compareOldAndNewsEndpoints(oldEnpoints, newEndpoints);

      if (!Object.keys(this.updateEndpoints).length) {
        return Swal.fire({
          title: "Los endpoints se mantienen iguales",
          timer: 1200,
          icon: "info",
        });
      }

      try {
        await this.updateURLs({ operator: this.item, endpoints: this.updateEndpoints });

        if (!this.success) return Swal.fire('Error actualizando URLs', '', 'error');

        Swal.fire({
          title: "URLs actualizados",
          timer: 1200,
          icon: "success",
        })
        this.$emit("getData");
        this.closeModal()
        this.checkErrors()
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "Error al actualizar URLs",
          timer: 1200,
          icon: "error",
        });
      }

    },
    closeModal() {
      this.$emit("closeModa");
    },
    ...mapActions({
      updateURLs: 'operators/updateUrl'
    })
  },
};
</script>

<style></style>
