<template>
  <b-modal id="bv-modal-limits" v-model="configLimits" size="lg" hide-footer>
    <template #modal-title>
      <h5>{{ $t('configLimits.title') }}</h5>
    </template>

    <b-tabs v-if="currencies.length || currWithOutLimits.length">
      <b-tab active v-if="currencies.length">
        <template v-if="isUpdating" #title>
          {{ isUpdating ? $t('configLimits.withLimits') : $t('configLimits.withOutLimits') }}
        </template>
        <div class="row mb-4">
          <div class="my-3 row px-2">
            <div class="col-3 text-center"><strong>{{ $t('configLimits.currency') }}.</strong></div>
            <div class="col-3 text-center"><strong>{{ $t('configLimits.minBet') }}</strong></div>
            <div class="col-3 text-center"><strong>{{ $t('configLimits.maxBet') }}</strong></div>
            <div class="col-3 text-center">
              <strong>{{ $t('configLimits.betByPosition') }}</strong>
            </div>
          </div>
          <div v-if="currencies.length">
            <form @submit.prevent="save">
              <div v-for="(currency, index) in currencies" :key="index">
                <div class="card p-2">
                  <div class="row align-items-center">
                    <div class="col-3">
                      <p class="m-0">
                        {{ $t('configLimits.currency') }}: <strong>{{ currency.short }}</strong>
                      </p>
                    </div>
                    <div class="col-3">
                      <vs-input @keyup="(e) => handleChange(e, currency)" :value="currency.minBet ? currency.minBet : 0"
                        type="text" class="shadow-lg" border name="minBet" :placeholder="$t('configLimits.minBet')"
                        minlength="1" required />
                    </div>
                    <div class="col-3">
                      <vs-input @keyup="(e) => handleChange(e, currency)" :value="currency.maxBet ? currency.maxBet : 0"
                        type="text" class="shadow-lg" border name="maxBet" :placeholder="$t('configLimits.maxBet')"
                        minlength="1" required />
                    </div>
                    <div class="col-3">
                      <vs-input @keyup="(e) => handleChange(e, currency)" :value="currency.maxBetPosition ? currency.maxBetPosition : 0
                        " type="text" class="shadow-lg" border name="maxBetPosition"
                        :placeholder="$t('configLimits.betByPosition')" minlength="1" required />
                    </div>
                  </div>
                  <vs-button type="button" class="delete-limit" success @click="deleteLimit(currency)">
                    <span class="uil-trash-alt text-light"></span>
                  </vs-button>
                </div>
              </div>
              <div class="mb-3 mx-auto">
                <div class="d-flex justify-content-start">
                  <vs-button type="submit">
                    {{ isUpdating ? $t('helpers.update') : $t('helpers.add') }}
                  </vs-button>
                  <vs-button @click="closeModa()" success type="button">
                    {{ $t('form.abort') }}
                  </vs-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </b-tab>
      <b-tab title="Monedas sin limites" v-if="currWithOutLimits.length">
        <div class="my-3 row px-2">
          <div class="col-3 text-center"><strong>{{ $t('configLimits.currency') }}.</strong></div>
          <div class="col-3 text-center"><strong>{{ $t('configLimits.minBet') }}</strong></div>
          <div class="col-3 text-center"><strong>{{ $t('configLimits.maxBet') }}</strong></div>
          <div class="col-3 text-center">
            <strong>{{ $t('configLimits.betByPosition') }}</strong>
          </div>
        </div>
        <div v-if="currWithOutLimits.length">
          <form @submit.prevent="saveNewLimits">
            <div v-for="(currency, index) in currWithOutLimits" :key="index">
              <div class="card p-2">
                <div class="row align-items-center">
                  <div class="col-3">
                    <p class="m-0">
                      {{ $t('configLimits.currency') }}: <strong>{{ currency.short }}</strong>
                    </p>
                  </div>
                  <div class="col-3">
                    <vs-input @keyup="(e) => handleUpdate(e, currency)" class="shadow-lg" type="text" border name="minBet"
                      minlength="1" required />
                  </div>
                  <div class="col-3">
                    <vs-input @keyup="(e) => handleUpdate(e, currency)" class="shadow-lg" type="text" border name="maxBet"
                      minlength="1" required />
                  </div>
                  <div class="col-3">
                    <vs-input @keyup="(e) => handleUpdate(e, currency)" class="shadow-lg" type="text" border
                      name="maxBetPosition" minlength="1" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mx-auto">
              <div class="d-flex">
                <vs-button>{{ $t('form.save') }}</vs-button>
                <vs-button @click="closeModa()" success type="button">{{ $t('form.abort') }}</vs-button>
              </div>
            </div>
          </form>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: [
    "currencies",
    "operator",
    "limitsByCurrencies",
    "isUpdating",
    "currWithOutLimits",
    "noLimitsCurrencies",
  ],
  data() {
    return {
      configLimits: false,
      typesubmit: false,
      typeform: {},
      limits: [],
      newLimits: [],
      errors: {},
    };
  },

  methods: {
    validateDecimal(valor) {
      let RE = /^\d*(\.\d{1})?\d{0,1}$/;
      if (RE.test(valor)) {
        return true;
      } else {
        return false;
      }
    },
    async deleteLimit(currency) {
      const { isConfirmed } = await Swal.fire({
        title: "¿Estas seguro de eliminar este limite?",
        showDenyButton: true,
        denyButtonText: "No",
        confirmButtonText: "Si",
        icon: "info",
        confirmButtonColor: "primary",
        denyButtonColor: "secondary",
      });

      if (!isConfirmed) {
        return this.closeModa();
      }

      const id = currency.limitId;
      const { data } = await this.$http.delete(
        `operators/${id}/delete-currency-limits`,
        { rouletteId: this.operator.roulette._id }
      );

      if (!data.ok) {
        return Swal.fire("Error eliminando limite");
      }

      this.closeModa();
      return Swal.fire("Limite eliminado", "", "success");
    },
    async save() {

      if (Object.entries(this.errors).length) {
        return Swal.fire({
          title: "Error en el formulario. Verifique por favor",
          html: this.mapErrors(this.errors),
          icon: "info"
        })
      }

      if (this.isUpdating) {
        const { data } = await this.$http.put(
          `operators/${this.operator._id}/update-currency-limits`,
          {
            limits: this.limits,
            rouletteId: this.operator.roulette._id
          }
        );
        if (data.ok) {
          this.closeModa();
          return Swal.fire({
            title: "Limites actualizados",
            icon: "success",
            timer: 1000,
          });
        }

        return Swal.fire({
          title: "Error actualizando limites",
          icon: "error",
          timer: 1000,
        });
      } else {

        const { data } = await this.$http.post(
          `operators/${this.operator._id}/add-currency-limits`,
          {
            limits: this.limits,
            rouletteId: this.operator.roulette._id
          }
        );
        if (data.ok) {
          this.closeModa();
          return Swal.fire({
            title: "Limites agregados",
            icon: "success",
            timer: 1500,
            position: "center",
          });
        }
        return Swal.fire({
          title: data.msg,
          icon: "info",
          timer: 1500,
          position: "center",
        });
      }
    },
    async saveNewLimits() {

      if (Object.entries(this.errors).length) {
        return Swal.fire({
          title: "Error en el formulario. Verifique por favor",
          html: this.mapErrors(this.errors),
          icon: "info"
        })
      }

      try {
        const { data } = await this.$http.post(
          `operators/${this.operator._id}/add-currency-limits`,
          {
            limits: this.newLimits,
            rouletteId: this.operator.roulette._id

          }
        );
        if (data.ok) {
          this.closeModa();
          return Swal.fire({
            title: "Limites agregados",
            icon: "success",
            timer: 1500,
            position: "center",
          });
        }
        return Swal.fire({
          title: data.msg,
          icon: "info",
          timer: 1500,
          position: "center",
        });
      } catch (err) {
        console.log("Error guardando nuevos limites", err);
        return Swal.fire({
          title: "Error guardando limites",
          icon: "info",
          timer: 1500,
          position: "center",

        });
      }
    },
    closeModa() {
      this.configLimits = false;
      this.errors = {}
      this.limits = []
      this.$emit("closeModa");
    },
    handleChange(e, currency) {
      const { value, name } = e.target;

      this.handleErrors(value, name, currency);

      const currencyIndex = this.getIndex(this.limitsByCurrencies, currency);

      this.limits[currencyIndex] = {
        ...this.limits[currencyIndex],
        short: currency.short,
        currency: currency._id,
        [name]: value,
      };
    },
    handleUpdate(e, currency) {
      const { value, name } = e.target;

      this.handleErrors(value, name, currency);

      const currencyIndex = this.getIndex(this.noLimitsCurrencies, currency);

      this.newLimits[currencyIndex] = {
        ...this.newLimits[currencyIndex],
        short: currency.short,
        currency: currency._id,
        [name]: value,
      };


    },
    getIndex(limits, currency) {
      let currencyIndex = 0;
      limits.filter((curr, index) => {
        if (curr.currency === currency.short) {
          return (currencyIndex = index);
        }
        return "";
      });
      return currencyIndex;
    },
    handleErrors(value, name, currency) {

      if (!value) return

      if (!this.validateDecimal(value)) {
        this.errors[currency.short] = {
          ...this.errors[currency.short],
          [name]: value,
        }
      } else {
        if (this.errors[currency.short]) {
          delete this.errors[currency.short][name];

          if (!Object.entries(this.errors[currency.short]).length) {
            delete this.errors[currency.short]
          }
        }
      }

    },
    mapErrors(errors) {
      let message = "";
      for (let error in errors) {

        for (let detail in errors[error]) {
          const value = errors[error][detail];
          message += `<strong>${detail}: </strong>${value}</br>`
        }
      }

      return message
    }
  },
};
</script>

<style scoped>
.delete-limit {
  position: absolute;
  right: -10px;
  top: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}
</style>
