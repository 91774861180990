<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      {{ operator.name }}
    </template>
    <div>
      <b-table striped hover :items="item" :fields="makeFields">
        <template #cell(actions)="data" v-if="role === 'ADMIN'">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <i class="fa fa-fw fa-bars font-size-16" />
            </template>
            <b-dropdown-item @click="configLimits(data.item)">
              <i class="uil-file-alt m-2 font-size-18" />
              <span class="align-middle ml-5">{{
                $t("actions.subItems.configLimits")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="configPayments(data.item)">
              <i class="uil-paypal m-2 font-size-18" />
              <span class="align-middle ml-5">Configurar pagos</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <b-modal v-model="limitModal" @ok.prevent="saveLimits" size="lg">
      <LimitsByCurrencies
        :operator="operator"
        :currencies="currencies"
        @update-limits="updateLimits"
      />
    </b-modal>
    <b-modal v-model="resolveBetModal" @ok.prevent="saveResolveBets" size="lg">
      <resolve-bets
        :map-resolve-bets="getResolveBets"
        @new-resolve-bets="handleChange"
      />
    </b-modal>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LimitsByCurrencies from "./LimitsByCurrencies.vue";
import ResolveBets from "./ResolveBets.vue";

export default {
  props: {
    item: {
      type: Array,
      default: () => [
        {
          name: "Black-Jackson",
        },
      ],
    },
    role: String,
    operator: {
      type: Object,
      default: () => ({ name: "Default" }),
    },
  },
  computed: {
    ...mapGetters({
      makeFields: "operators/getFieldsBlackJack",
      getLimitsFormatted: "operators/getLimitsFormatted",
      getResolveBets: "operators/getResolveBets",
      getNewResolveBets: "operators/getNewResolveBets",
      success: "operators/getSuccess",
    }),
  },
  data() {
    return {
      modal: false,
      currencies: [],
      limitModal: false,
      resolveBetModal: false,
      limits: {},
      game: {},
      pays: {},
    };
  },
  methods: {
    configLimits(data) {
      const { currencyAndLimits } = data;
      this.currencies = currencyAndLimits.map(
        ({ currency, minBet, maxBet }) => ({
          minBet,
          maxBet,
          short: currency.isoCode,
          ...currency,
        })
      );
      this.limitModal = true;
      this.game = data;
    },
    async configPayments(data) {
      const { uuid } = data;
      await this.fetchResolveBets({
        gameUuid: uuid,
        operatorUuid: this.operator.uuid,
      });
      this.resolveBetModal = true;
      this.game = data;
    },
    updateLimits(limits) {
      this.limits = limits;
    },
    async saveLimits() {
      const limits = this.getLimitsFormatted(
        this.currencies,
        this.limits,
        this.game
      );
      await this.addBlackJack({ operatorId: this.operator.uuid, limits });

      if (!this.success)
        return this.$swal.fire("Error actualizando limites", "", "error");

      this.$swal.fire("Limites actualizados", "", "success");
      this.closeModal();
    },
    async saveResolveBets() {
      const newPays = this.getNewResolveBets(this.pays, this.getResolveBets);
      await this.updateResolveBets({
        payments: newPays,
        operatorUuid: this.operator.uuid,
        gameUuid: this.game.uuid,
      });

      if (!this.success)
        return this.$swal.fire("Error actualizando pagos", "", "error");

      this.$swal.fire("Pagos actualizados", "", "success");
      this.closeModal();
    },
    closeModal() {
      this.modal = false;
      this.resolveBetModal = false;
      this.limitModal = false;
      this.$emit("closeModal");
    },
    handleChange(values) {
      this.pays = values;
    },
    ...mapActions({
      fetchResolveBets: "operators/fetchResolveBets",
      updateResolveBets: "operators/updateResolveBets",
      addBlackJack: "operators/addBlackJack",
    }),
  },
  components: { LimitsByCurrencies, ResolveBets },
};
</script>
