export const formatBetLimits = (limits, betType) => {
  const withLimits = []
  const withOutLimits = []
  
  for (let limit of limits) {
    
    if (limit[betType]) {
      withLimits.push(limit);
    } else {
      withOutLimits.push(limit)
    }
  }

  return {withLimits,withOutLimits}
}