<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      {{ operator.name }}
    </template>
    <div>
      <b-table striped hover :items="item" :fields="makeFields()">
        <template #cell(actions)="data" v-if="role === 'ADMIN'">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <i class="fa fa-fw fa-bars font-size-16" />
            </template>
            <b-dropdown-item @click="configLimits(data.item)">
              <i class="uil-file-alt m-2 font-size-18" />
              <span class="align-middle ml-5">{{
                $t("actions.subItems.configLimits")
              }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="configPayments(data.item)">
              <i class="uil-paypal m-2 font-size-18" />
              <span class="align-middle ml-5">Configurar pagos</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <ConfigPayments
      :operator="operator"
      :payments="paysToEdit"
      :game="game"
      ref="configPaymentsComponent"
      @closeModal="closeModa" />
    <b-modal v-model="limitModal" @ok.prevent="saveLimits" size="lg">
      <Limits
        :operator="operator"
        :currencies="currencies"
        @update-limits="updateLimits" />
    </b-modal>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ConfigPayments from "./ConfigPayments.vue";
import Limits from "./Limits.vue";
// import Swal from "sweetalert2";

export default {
  components: { ConfigPayments, Limits },
  props: {
    item: {
      type: Array,
      default: () => {
        return [];
      },
    },
    games: {
      type: Array,
      default: () => {
        return [];
      },
    },
    operator: Object,
    role: String,
  },
  data() {
    return {
      modal: false,
      fields: [],
      paysToEdit: [],
      game: {},
      currencies: [],
      limitModal: false,
      newLimits: {},
    };
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess",
      limits: "operators/getLimits",
      getLimitsFormatted: "operators/getLimitsFormatted",
      getCurrencies: "currencies/getCurrencies",
    }),
  },
  methods: {
    makeFields() {
      const globalFields = [
        {
          key: "name",
          label: "RUEDA",
          class: "text-center",
        },
      ];
      const adminFields = [
        {
          key: "actions",
          sortable: false,
          label: this.$t("actions.text"),
          class: "align-middle  text-center",
        },
      ];
      if (this.role === "ADMIN") {
        return [...globalFields, ...adminFields];
      }

      return globalFields;
    },
    closeModa() {
      this.modal = false;
      this.limitModal = false;
      this.$emit("closeModal");
    },
    configLimits(item) {
      const { currencyAndLimits } = item;

      this.currencies = currencyAndLimits.map(
        ({ currency, minBet, maxBet }) => ({
          minBet,
          maxBet,
          short: currency.isoCode,
          ...currency,
        })
      );

      const currenciesWithOutLimits = this.operator.currencies
        .filter(
          (curr) => !this.currencies.find(({ isoCode }) => isoCode === curr)
        )
        .map((short) => {
          const currency = this.getCurrencies.find((c) => c.short === short);

          return {
            ...currency,
            minBet: 0,
            maxBet: 0,
            short,
          };
        });

      this.currencies.push(...currenciesWithOutLimits);

      this.limitModal = true;
      this.game = item;
    },
    updateLimits(limits) {
      this.newLimits = limits;
    },
    async saveLimits() {
      const limits = this.getLimitsFormatted(
        this.currencies,
        this.newLimits,
        this.game
      );

      await this.addWheel({
        operatorId: this.operator.uuid,
        wheelLimits: limits,
      });

      if (!this.success)
        return this.$swal.fire("Error actualizando limites", "", "error");

      this.$swal.fire("Limites actualizados", "", "success");
      this.closeModa();
    },
    async configPayments(item) {
      this.paysToEdit = await this.getPaysToEdit({
        games: this.games,
        operator: this.operator,
        item,
      });
      this.game = item;
      this.$refs.configPaymentsComponent.modal = true;
    },
    ...mapActions({
      getPaysToEdit: "operators/getPaysToEdit",
      addWheel: "operators/addWheel",
    }),
  },
};
</script>
