<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar Rueda </template>
    <div>
      <form @submit.prevent="save()">
        <div class="mb-3 mx-auto">
          <multiselect v-model="value" label="name" track-by="name" :options="wheels" @tag="addTag">
          </multiselect>
        </div>

        <template v-if="value">
          <div v-for="(currency, index) in currencies" :key="index" class="mt-4">
            <div class="card p-2" v-if="currency.uuid">
              <div class="row align-items-center">
                <div class="col-4">
                  <p class="m-0">
                    {{ $t('configLimits.currency') }}: <strong>{{ currency.short }}</strong>
                  </p>
                </div>
                <div class="col-4">
                  <vs-input @keyup="(e) => handleLimit(e, currency)" type="text" class="shadow-lg" border name="minBet"
                    :label="$t('configLimits.minBet')" minlength="1" required />
                </div>
                <div class="col-4">
                  <vs-input @keyup="(e) => handleLimit(e, currency)" type="text" class="shadow-lg" border name="maxBet"
                    :label="$t('configLimits.maxBet')" minlength="1" required />
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- <WheelPayments :bet-pays="betPays" @update-payment="handleChange" /> -->

        <div class="d-flex">
          <vs-button type="submit">Guardar</vs-button>
          <vs-button @click="closeModa()" type="button" success>
            Cancelar
          </vs-button>
        </div>
      </form>
    </div>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    operator: {
      type: Object,
      default: () => {
        return {};
      },
    },
    wheels: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
    currencies: {
      typeof: Array,
      default: () => {
        return [];
      },
    },
  },
  components: { Multiselect, /* WheelPayments */ },
  data() {
    return {
      typesubmit: false,
      modal: false,
      betPays: [],
      value: null,
      pays: {},
      limits: {}
    };
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess",
    }),
  },
  methods: {
    addTag(newTag) {
      const tag = newTag;

      this.value.push(tag);
    },
    async save() {
      // const defaultPays = this.betPays.map(item => ({ optionBet: item.number, pay: item.multiplier }))
      // const defaultPaysDoc = defaultPays.reduce((acc, value) => (Object.assign(acc, { [value.optionBet]: value })), {})
      // const newPays = Object.values(this.pays).reduce((acc, value) => (Object.assign(acc, { [value.optionBet]: value })), {})
      // const paysMerged = { ...defaultPaysDoc, ...newPays };
      // const paysToSave = Object.values(paysMerged);
      // console.log('paysToSave', paysToSave);

      this.typesubmit = true;

      await this.addWheel({ operatorId: this.operator.uuid, wheelLimits: this.limits })

      if (!this.success) return Swal.fire("Error adding wheel", "", "info");

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Rueda agregada con exito",
        showConfirmButton: false,
        timer: 1500,
      });
      this.closeModa();
    },
    closeModa() {
      this.betPays = [];
      this.pays = {};
      this.limits = {}
      this.modal = false;
      this.$emit("closeGameMenu");
    },
    handleChange({ index, value, name }) {
      this.pays[index] = {
        ...this.pays[index],
        [name]: Number(value),
      }
    },
    handleLimit(e, currency) {
      const { name, value } = e.target

      this.limits[currency.short] = {
        ...this.limits[currency.short],
        currencyUuid: currency.uuid,
        gameUuid: this.value.uuid,
        [name]: Number(value),
      };
    },
    ...mapActions({
      addWheel: "operators/addWheel",
    }),
  },
  watch: {
    value(newValue) {
      const { betPays } = newValue;

      this.betPays = [...betPays];
    },
  },
};
</script>
