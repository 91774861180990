var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"bv-modal-example","size":"lg","hide-footer":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" Agregar Ruleta ")]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('div',{staticClass:"mb-3 mx-auto"},[_c('multiselect',{attrs:{"label":"name","track-by":"name","options":_vm.roulettes},on:{"tag":_vm.addTag},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Pleno")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.pleno.$error,
            },attrs:{"border":""},model:{value:(_vm.typeform.pleno),callback:function ($$v) {_vm.$set(_vm.typeform, "pleno", $$v)},expression:"typeform.pleno"}}),(_vm.typesubmit && _vm.$v.typeform.pleno.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.pleno.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Semi Pleno")]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.semipleno.$error,
            },attrs:{"border":""},model:{value:(_vm.typeform.semipleno),callback:function ($$v) {_vm.$set(_vm.typeform, "semipleno", $$v)},expression:"typeform.semipleno"}}),(_vm.typesubmit && _vm.$v.typeform.semipleno.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.semipleno.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Calle ")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.calle.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.calle),callback:function ($$v) {_vm.$set(_vm.typeform, "calle", $$v)},expression:"typeform.calle"}}),(_vm.typesubmit && _vm.$v.typeform.calle.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.calle.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Cuadro")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.cuadro.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.cuadro),callback:function ($$v) {_vm.$set(_vm.typeform, "cuadro", $$v)},expression:"typeform.cuadro"}}),(_vm.typesubmit && _vm.$v.typeform.cuadro.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.cuadro.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Linea")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.linea.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.linea),callback:function ($$v) {_vm.$set(_vm.typeform, "linea", $$v)},expression:"typeform.linea"}}),(_vm.typesubmit && _vm.$v.typeform.linea.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.linea.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Columna")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.columna.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.columna),callback:function ($$v) {_vm.$set(_vm.typeform, "columna", $$v)},expression:"typeform.columna"}}),(_vm.typesubmit && _vm.$v.typeform.columna.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.columna.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Docena")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.docena.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.docena),callback:function ($$v) {_vm.$set(_vm.typeform, "docena", $$v)},expression:"typeform.docena"}}),(_vm.typesubmit && _vm.$v.typeform.docena.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.docena.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Chance simple")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.chanceSimple.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.chanceSimple),callback:function ($$v) {_vm.$set(_vm.typeform, "chanceSimple", $$v)},expression:"typeform.chanceSimple"}}),(_vm.typesubmit && _vm.$v.typeform.chanceSimple.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.chanceSimple.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Cubre")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.cubre.$error,
              },attrs:{"border":""},model:{value:(_vm.typeform.cubre),callback:function ($$v) {_vm.$set(_vm.typeform, "cubre", $$v)},expression:"typeform.cubre"}}),(_vm.typesubmit && _vm.$v.typeform.cubre.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.cubre.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("Special Calle")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",attrs:{"border":""},model:{value:(_vm.typeform.specialCalle),callback:function ($$v) {_vm.$set(_vm.typeform, "specialCalle", $$v)},expression:"typeform.specialCalle"}})],1)])])],1),_c('div',{staticClass:"d-flex"},[_c('vs-button',{attrs:{"type":"submit"}},[_vm._v("Guardar")]),_c('vs-button',{attrs:{"type":"button","success":""},on:{"click":function($event){return _vm.closeModa()}}},[_vm._v(" Cancelar ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }