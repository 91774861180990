var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("operators.name")))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
          },attrs:{"border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("UUID")]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"border":""},model:{value:(_vm.typeform.uuid),callback:function ($$v) {_vm.$set(_vm.typeform, "uuid", $$v)},expression:"typeform.uuid"}})],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("operators.form.client")))]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.client),expression:"typeform.client"}],staticClass:"form-select border-0 shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.client.$error,
            },attrs:{"name":"client","placeholder":"Seleccione.."},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "client", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.handleChange]}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.clients),function(item,index){return _c('option',{key:index,domProps:{"value":item,"selected":_vm.typeform.client === item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.client.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.client.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("operators.form.endpointAuth")))]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.endpointAuth.$error,
            },attrs:{"border":"","placeholder":"/auth"},model:{value:(_vm.typeform.endpointAuth),callback:function ($$v) {_vm.$set(_vm.typeform, "endpointAuth", $$v)},expression:"typeform.endpointAuth"}}),(_vm.typesubmit && _vm.$v.typeform.endpointAuth.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.endpointAuth.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("operators.form.endpointBet")))]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.endpointBet.$error,
            },attrs:{"border":"","placeholder":"/bet"},model:{value:(_vm.typeform.endpointBet),callback:function ($$v) {_vm.$set(_vm.typeform, "endpointBet", $$v)},expression:"typeform.endpointBet"}}),(_vm.typesubmit && _vm.$v.typeform.endpointBet.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.endpointBet.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("operators.form.endpointWin")))]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.endpointWin.$error,
            },attrs:{"border":"","placeholder":"/win"},model:{value:(_vm.typeform.endpointWin),callback:function ($$v) {_vm.$set(_vm.typeform, "endpointWin", $$v)},expression:"typeform.endpointWin"}}),(_vm.typesubmit && _vm.$v.typeform.endpointWin.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.endpointWin.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("operators.form.endpointRollback")))]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.endpointRollback.$error,
            },attrs:{"border":"","placeholder":"/rollback"},model:{value:(_vm.typeform.endpointRollback),callback:function ($$v) {_vm.$set(_vm.typeform, "endpointRollback", $$v)},expression:"typeform.endpointRollback"}}),(_vm.typesubmit && _vm.$v.typeform.endpointRollback.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.endpointRollback.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v("URL GAMES")]),_c('div',[_c('vs-input',{staticClass:"shadow-lg",attrs:{"border":"","placeholder":"https://another-games.com"},model:{value:(_vm.typeform.urlGames),callback:function ($$v) {_vm.$set(_vm.typeform, "urlGames", $$v)},expression:"typeform.urlGames"}})],1)])])],1),(!_vm.typeform._id)?_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("initSession.role")))]),_c('label',{staticClass:"d-inline-flex align-items-center w-100"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.role),expression:"typeform.role"}],staticClass:"form-select form-control border-0 shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.role.$error,
            },attrs:{"name":"role","placeholder":"Seleccione..","required":_vm.typeform._id ? false : true},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.roles),function(item,index){return _c('option',{key:index,domProps:{"value":item._id,"selected":_vm.typeform.role == item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.role.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.role.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("initSession.password")))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.password.$error,
          },attrs:{"type":"password","border":"","required":_vm.typeform._id ? false : true},model:{value:(_vm.typeform.password),callback:function ($$v) {_vm.$set(_vm.typeform, "password", $$v)},expression:"typeform.password"}}),(_vm.typesubmit && _vm.$v.typeform.password)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.password.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)])],1):_vm._e(),_c('div',{staticClass:"mb-3 mb-0"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',[_vm._v(_vm._s(_vm.$t("form.save")))]),_c('vs-button',{attrs:{"success":"","type":"button"},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" "+_vm._s(_vm.$t("form.abort"))+" ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }