<template>
  <div>
    <div v-for="(bet, i) in mapResolveBets" :key="i">
      <template v-if="typeof bet === 'object'">
        <h5 class="my-3">{{ $t(`black_jack_bet_resolve.${i}.title`) }}</h5>
        <b-row>
          <b-col v-for="(type, j) in bet" :key="j" sm="12" md="6">
            <div class="mb-3">
              <label>{{ $t(`black_jack_bet_resolve.${i}.${j}`) }}</label>
              <vs-input :value="type" :name="j" border class="shadow-lg" @change="(e) => handleChange(e, i, j)" />
            </div>
          </b-col>
        </b-row>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mapResolveBets: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pays: {}
    }
  },
  methods: {
    handleChange(e, i, j) {
      const { value } = e.target;
      this.pays[i] = {
        ...this.pays[i],
        [j]: Number(value),
      };

      this.$emit('new-resolve-bets', this.pays)
    },
  }
}
</script>
