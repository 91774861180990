<template>
  <div>
    <h5>{{ $t("configLimits.title") }}</h5>

    <div class="my-3 row px-2">
      <div class="col-4 text-center">
        <strong>{{ $t("configLimits.currency") }}.</strong>
      </div>
      <div class="col-4 text-center">
        <strong>{{ $t("configLimits.minBet") }}</strong>
      </div>
      <div class="col-4 text-center">
        <strong>{{ $t("configLimits.maxBet") }}</strong>
      </div>
    </div>
    <div>
      <div v-for="(currency, index) in currencies" :key="index">
        <div class="card p-2">
          <div class="row align-items-center">
            <div class="col-4">
              <p class="m-0">
                {{ $t("configLimits.currency") }}:
                <strong>{{ currency.short }}</strong>
              </p>
            </div>
            <div class="col-4">
              <vs-input
                @keyup="(e) => handleUpdate(e, currency)"
                :value="currency.minBet || null"
                class="shadow-lg"
                type="text"
                border
                name="minBet"
                minlength="1"
                required />
            </div>
            <div class="col-4">
              <vs-input
                @keyup="(e) => handleUpdate(e, currency)"
                :value="currency.maxBet || null"
                class="shadow-lg"
                type="text"
                border
                name="maxBet"
                minlength="1"
                required />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currencies"],
  data() {
    return {
      configLimits: false,
      typesubmit: false,
      typeform: {},
      limits: [],
      newLimits: {},
      errors: {},
    };
  },

  methods: {
    handleUpdate(e, currency) {
      const { value, name } = e.target;

      const { short, _id } = currency;

      this.newLimits[short] = {
        ...currency,
        ...this.newLimits[short],
        short,
        currency: _id,
        [name]: value,
      };

      this.$emit("update-limits", this.newLimits);
    },
  },
};
</script>

<style scoped>
.delete-limit {
  position: absolute;
  right: -10px;
  top: 0;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
}
</style>
