<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title>
      VERSION DE LAYOUT DE LA RULETA
    </template>
    <div class="d-flex justify-content-center">
      <vs-tooltip>
        <span>LAYOUT V2:</span>
        <vs-switch v-model="layout" class="m-1" />
        <template #tooltip>Habilitar para la version 2 del layout</template>
      </vs-tooltip>
    </div>
    <div class="d-flex justify-content-center">
      <vs-button @click="save">GUARDAR</vs-button>
      <vs-button @click="closeModa" success>CANCELAR</vs-button>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      layout: false,
      game: {},
      operatorId: ''
    }
  },
  methods: {
    async save() {
      try {
        const { data } = await this.$http
          .put(
            `operators/${this.operatorId}/update-roulette/${this.game._id}`,
            { layout: this.layout }
          )

        if (!data.ok) return this.$swal.fire("Error editando layout", "", "error")

        this.$swal.fire({
          position: "center",
          icon: "success",
          title: "Layout actualizado con exito",
          showConfirmButton: false,
          timer: 1500
        })

        this.closeModa();

      } catch (error) {
        console.log("ERROR EDIT OPERATOR", error)
      }
    },
    closeModa() {
      this.$emit("closeModal");
      this.modal = false;
    },
  }
}
</script>

<style lang="scss" scoped></style>