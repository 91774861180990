<template>
  <b-modal v-model="modal" size="xl" hide-footer centered>
    <template #modal-title> BOTONERIA </template>
    <div class="d-flex justify-content-center">
      <vs-tooltip>
        <span>IR AL LOBBY:</span>
        <vs-switch v-model="buttonLobby" class="m-1" />
        <template #tooltip
          >Habilitar para mostrar boton de ir al lobby</template
        >
      </vs-tooltip>
    </div>
    <div class="d-flex justify-content-center">
      <vs-tooltip>
        <span>SOPORTE:</span>
        <vs-switch v-model="buttonSupport" class="m-1" />
        <template #tooltip>Habilitar para mostrar boton de soporte</template>
      </vs-tooltip>
    </div>
    <div class="d-flex justify-content-center">
      <vs-button @click="save">GUARDAR</vs-button>
      <vs-button @click="closeModa" success>CANCELAR</vs-button>
    </div>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      modal: false,
      buttonLobby: false,
      buttonSupport: false,
      operator: {},
    };
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess",
      envs: "notification/mapEnvs",
      getErrors: "notification/getErrors",
    }),
  },
  methods: {
    async save() {
      try {
        await this.update({
          doc: {
            ...this.operator,
            buttonLobby: this.buttonLobby,
            buttonSupport: this.buttonSupport,
          },
        });
        if (!this.success)
          return Swal.fire({
            position: "center",
            icon: "error",
            title: "Error actualizando el operador",
            showConfirmButton: false,
            timer: 1500,
          });

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Operador guardado con exito",
          showConfirmButton: false,
          timer: 1500,
        });

        this.checkErrors();

        this.closeModa();
      } catch (error) {
        console.log("ERROR EDIT OPERATOR", error);
      }
    },
    checkErrors() {
      if (this.getErrors.length) {
        this.getErrors.map(({ api, reason }) => {
          const message = `API: ${this.envs[api]} | REASON: ${reason}`;
          this.openWinNotification(null, "warning", "ERROR", message);
        });
      }
    },
    closeModa() {
      this.$emit("closeModal");
      this.operator = {};
      this.buttonLobby = false;
      this.buttonSupport = false;
      this.modal = false;
    },
    ...mapActions({
      update: "operators/update",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
