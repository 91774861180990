export const formatDropValue = (e, orderChips) => {
  const dragged = JSON.parse(e.dataTransfer.getData("item"));
  const drop = e.target.closest(".card-chip");

  const draggedOrder = dragged.order;
  const orderValue = Number(drop.querySelector(".order").textContent);

  const filterChips = orderChips.filter(chip => ![draggedOrder, orderValue].includes(chip.order));
  const toChangeChip = orderChips.find(chip => chip.order === orderValue);
  dragged.order = orderValue;
  toChangeChip.order = draggedOrder;
  filterChips.push(dragged, toChangeChip);
  return filterChips.sort((a, b) => a.order - b.order);
}

export const formatOrderGames = (e, games) => {
  const dragged = JSON.parse(e.dataTransfer.getData("item"));
  const drop = e.target.closest(".card-chip");

  const draggedOrder = dragged.order;
  const orderValue = Number(drop.querySelector(".order").textContent);

  if (draggedOrder === orderValue) return games

  const filterGames = games.filter(game => ![draggedOrder, orderValue].includes(game.order));
  const toChangeChip = games.find(game => game.order === orderValue);

  dragged.order = orderValue;
  toChangeChip.order = draggedOrder;

  filterGames.push(dragged, toChangeChip);
  return filterGames.sort((a, b) => a.order - b.order);
}