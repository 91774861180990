<template>
  <b-modal id="bv-modal-limits" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      <h5>LIMITS: {{ betType.toUpperCase() }}</h5>
    </template>
    <b-tabs v-if="betsLimits.length || betsWithoutLimits.length">
      <b-tab active v-if="betsLimits.length">
        <template #title>
          Bets con limites
        </template>
        <div class="my-3 row px-2">
          <div class="col-3 text-center"><strong>{{ $t('configLimits.currency') }}.</strong></div>
          <div class="col-3 text-center"><strong>{{ $t('configLimits.minBet') }}</strong></div>
          <div class="col-3 text-center"><strong>{{ $t('configLimits.maxBet') }}</strong></div>
        </div>
        <div v-if="betsLimits.length">
          <form @submit.prevent="saveNewLimits(false)">
            <div v-for="(currency, index) in betsLimits" :key="index">
              <div class="card p-2">
                <div class="row align-items-center">
                  <div class="col-3">
                    <p class="m-0">
                      {{ $t('configLimits.currency') }}: <strong>{{ currency.short }}</strong>
                    </p>
                  </div>
                  <div class="col-4">
                    <vs-input @keyup="(e) => handleChange(e, currency)"
                      :value="currency[betType] ? currency[betType].min : 0" class="shadow-lg" type="text" border
                      name="min" minlength="1" required />
                  </div>
                  <div class="col-4">
                    <vs-input @keyup="(e) => handleChange(e, currency)"
                      :value="currency[betType] ? currency[betType].max : 0" class="shadow-lg" type="text" border
                      name="max" minlength="1" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mx-auto">
              <div class="d-flex">
                <vs-button>{{ $t('form.save') }}</vs-button>
                <vs-button @click="closeModal()" success type="button">{{ $t('form.abort') }}</vs-button>
              </div>
            </div>
          </form>
        </div>
      </b-tab>
      <b-tab title="Bets sin limites" v-if="betsWithoutLimits.length">
        <div class="my-3 row px-2">
          <div class="col-3 text-center"><strong>{{ $t('configLimits.currency') }}.</strong></div>
          <div class="col-3 text-center"><strong>{{ $t('configLimits.minBet') }}</strong></div>
          <div class="col-3 text-center"><strong>{{ $t('configLimits.maxBet') }}</strong></div>
        </div>
        <div v-if="betsWithoutLimits.length">
          <form @submit.prevent="saveNewLimits(true)">
            <div v-for="(currency, index) in betsWithoutLimits" :key="index">
              <div class="card p-2">
                <div class="row align-items-center">
                  <div class="col-3">
                    <p class="m-0">
                      {{ $t('configLimits.currency') }}: <strong>{{ currency.short }}</strong>
                    </p>
                  </div>
                  <div class="col-4">
                    <vs-input @keyup="(e) => handleUpdate(e, currency)" class="shadow-lg" type="text" border name="min"
                      minlength="1" required />
                  </div>
                  <div class="col-4">
                    <vs-input @keyup="(e) => handleUpdate(e, currency)" class="shadow-lg" type="text" border name="max"
                      minlength="1" required />
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3 mx-auto">
              <div class="d-flex">
                <vs-button>{{ $t('form.save') }}</vs-button>
                <vs-button @click="closeModal()" success type="button">{{ $t('form.abort') }}</vs-button>
              </div>
            </div>
          </form>
        </div>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    betType: {
      type: String,
      default: () => "Ej: Pleno"
    },
    betsLimits: {
      type: Array,
      default: () => []
    },
    betsWithoutLimits: {
      type: Array,
      default: () => []
    },
    operator: {
      type: Object,
      default: () => ({})
    },
    roulette: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      modal: false,
      newLimits: [],
      limits: [],
      errors: []
    }
  },
  computed: {
    ...mapGetters({
      success: "operators/getSuccess"
    })
  },
  methods: {
    handleUpdate(e, curr) {


      const { value, name } = e.target;
      const { currency, short } = curr;
      this.handleErrors(value, name, short);
      const currencyIndex = this.getIndex(this.betsWithoutLimits, short);

      this.newLimits[currencyIndex] = {
        ...this.newLimits[currencyIndex],
        currency
      }
      this.newLimits[currencyIndex][this.betType] = {
        ...this.newLimits[currencyIndex][this.betType],
        [name]: value,
      }
    },
    handleChange(e, curr) {


      const { value, name } = e.target;
      const { currency, short } = curr;
      this.handleErrors(value, name, short);
      const currencyIndex = this.getIndex(this.betsLimits, short);

      this.limits[currencyIndex] = {
        ...this.limits[currencyIndex],
        currency
      }
      this.limits[currencyIndex][this.betType] = {
        ...this.limits[currencyIndex][this.betType],
        [name]: value,
      }
    },
    getIndex(limits, short) {
      let currencyIndex = 0;
      limits.filter((curr, index) => {
        if (curr.short === short) {
          return (currencyIndex = index);
        }
        return "";
      });
      return currencyIndex;
    },
    handleErrors(value, name, short) {

      if (!value) return

      if (!this.validateDecimal(value)) {
        this.errors[short] = {
          ...this.errors[short],
          [name]: value,
        }
      } else {
        if (this.errors[short]) {
          delete this.errors[short][name];

          if (!Object.entries(this.errors[short]).length) {
            delete this.errors[short]
          }
        }
      }

    },
    validateDecimal(valor) {
      let RE = /^\d*(\.\d{1})?\d{0,1}$/;
      if (RE.test(valor)) {
        return true;
      } else {
        return false;
      }
    },
    async saveNewLimits(update) {
      if (Object.entries(this.errors).length) {
        return Swal.fire({
          title: "Error en el formulario. Verifique por favor",
          html: this.mapErrors(this.errors),
          icon: "info"
        })
      }

      let dataToSave = []
      if (update) {
        dataToSave = this.newLimits
      } else {
        dataToSave = this.limits
      }

      try {
        await this.updateLimits({
          id: this.operator._id,
          rouletteId: this.roulette._id,
          limits: dataToSave
        });

        if (!this.success) return Swal.fire("Error guardando apuesta", "", "error");

        Swal.fire("Limite actualizado correctamente", "", "success");
        this.modal = false;
      } catch (error) {
        console.log('ERROR SAVING NEW LIMITS',);
      }
    },
    mapErrors(errors) {
      let message = "";
      for (let error in errors) {

        for (let detail in errors[error]) {
          const value = errors[error][detail];
          message += `<strong>${detail}: </strong>${value}</br>`
        }
      }

      return message
    },
    closeModal() {
      this.modal = false;
    },
    ...mapActions({
      updateLimits: "operators/updateOperatorRouletteLimits"
    })
  }
}
</script>